export const CreditInstitutionList = {
  NONE: { type: 'NONE', title: 'creditInsitution_list_NONE' },
  BANK: { type: 'BANK', title: 'creditInsitution_list_BANK' },
  NYK: { type: 'NYK', title: 'creditInsitution_list_NYK' },
  RD: { type: 'RD', title: 'creditInsitution_list_RD' },
  NOR: { type: 'NOR', title: 'creditInsitution_list_NOR' },
  TOT: { type: 'TOT', title: 'creditInsitution_list_TOT' },
  JYSK: { type: 'JYSK', title: 'creditInsitution_list_JYSK' },
  BRF: { type: 'BRF', title: 'creditInsitution_list_BRF' },
};
