import {
  AssetType,
  CalculationCountry,
  DkTaxCode,
  Owner,
  PaymentFrequency,
  PensionCoverage,
  Policy,
} from '@dreamplan/autogenerated-clients/calculation';
import { TestProfileWithAnswers } from '@dreamplan/types';
import { sharedDefaultAnswers } from '../sharedProfileAnswers';

export const CasperAndLouiseProfile: TestProfileWithAnswers = {
  name: 'Casper and Louise',
  answers: [
    ...sharedDefaultAnswers,
    {
      key: 'isUserSelfEmployed',
      value: 'false',
    },
    {
      key: 'userCurrentPensionMonthlyPayment',
      value: 0,
    },
    {
      key: 'spouseCurrentPensionMonthlyPayment',
      value: 0,
    },
    {
      key: 'userAge',
      value: 1985,
    },
    {
      key: 'userFullName',
      value: 'Casper',
    },
    {
      key: 'hasSpouse',
      value: 'true',
    },
    {
      key: 'spouseName',
      value: 'Louise',
    },
    {
      key: 'spouseAge',
      value: 38,
    },
    {
      key: 'userMonthlySalary',
      value: 42500,
    },
    {
      key: 'userCurrentPensionSavings',
      value: 764000,
    },
    {
      key: 'spouseMonthlySalary',
      value: 35000,
    },
    {
      key: 'spouseCurrentPensionSavings',
      value: 350000,
    },
    {
      key: 'houseAddress',
      value: 'test adresse',
    },
    {
      key: 'houseOwnershipType',
      value: 'Own',
    },
    {
      key: 'houseValue',
      value: 3150000,
    },
    {
      key: 'houseDebt',
      value: 1450000,
    },
    {
      key: 'houseDebtInterest',
      value: 2.2,
    },
    {
      key: 'houseDebtRemainingTenure',
      value: 29,
    },
    {
      key: 'houseDebtHasRepaymentFreeYears',
      value: 'false',
    },
    {
      key: 'hasSecondHouse',
      value: 'true',
    },
    {
      key: 'houseAddress',
      value: 'test adresse 2',
    },
    {
      key: 'houseOwnershipType',
      value: 'Own',
    },
    {
      key: 'houseValue',
      value: 850000,
    },
    {
      key: 'houseDebt',
      value: 673853,
    },
    {
      key: 'houseDebtInterest',
      value: 0,
    },
    {
      key: 'houseDebtRemainingTenure',
      value: 29,
    },
    {
      key: 'houseDebtHasRepaymentFreeYears',
      value: 'false',
    },
    {
      key: 'userMonthlySavingsAmount',
      value: 1000,
    },
    {
      key: 'userCurrentEmergencySavings',
      value: 120000,
    },
    {
      key: 'fetchPensionInformation',
      value: 'false',
    },
    {
      key: 'userName',
      value: 'Casper',
    },
  ],
  expectedPayload: {
    primary: {
      birthYear: 1985,
      pensionAge: 69,
      monthlySalary: 42500,
      deathAge: undefined,
    },
    spouse: {
      birthYear: 1986,
      monthlySalary: 35000,
      pensionAge: 69,
      deathAge: undefined,
    },
    policies: [
      {
        id: 'dd5f84da-066d-4610-8753-4d4e9fc8960p',
        owner: Owner.Primary,
        coverages: [
          {
            type: 'PensionCoverage',
            id: 'dd5f84da-066d-4610-8753-4d4e9fc8960p',
            owner: Owner.Primary,
            initialValue: 764000,
            taxCode: DkTaxCode.One,
            contribution: {
              paymentFrequency: PaymentFrequency.Monthly,
              amount: 0,
              isFromEmployer: undefined,
            },
            payOutType: {
              type: 'LifeAnnuity',
              payOutDate: undefined,
            },
          } as PensionCoverage,
        ],
      } as Policy,
      {
        id: 'dd5f84da-066d-4610-8753-4d4e9fc896sp',
        owner: Owner.Spouse,
        coverages: [
          {
            type: 'PensionCoverage',
            id: 'dd5f84da-066d-4610-8753-4d4e9fc896sp',
            owner: Owner.Spouse,
            initialValue: 350000,
            taxCode: DkTaxCode.One,
            contribution: {
              paymentFrequency: PaymentFrequency.Monthly,
              amount: 2100,
              isFromEmployer: undefined,
            },
            payOutType: {
              type: 'LifeAnnuity',
              payOutDate: undefined,
            },
          } as PensionCoverage,
        ],
      } as Policy,
    ],
    housesWithLoans: [
      {
        id: 'dd5f84da-066d-4610-8753-4d4e9fc8960f',
        houseValue: 4000000,
        type: 'HouseWithLoans',
        loans: [
          {
            id: 'd9497ef5-9d8a-4c03-8308-cbee6746339c',
            remainingPrincipal: 2123853,
            type: 'AmortizedLoan',
            contributionFeeRate: 0.006999999999999999,
            interestRate: 0.015019871902622264,
            redemptionDetails: {
              // bondPrice: 0,
              redemptionFee: 5000,
            },
            remainingRepaymentFreeYears: 0,
            remainingTenure: 29,
          },
        ],
      },
    ],
    liquidAssets: [
      {
        id: 'dd5f84da-066d-4610-8753-4d4e9fc8960h',
        assetType: AssetType.Stocks,
        initialValue: 120000,
        monthlyDeposit: {
          amount: 1000,
          paymentFrequency: PaymentFrequency.Monthly,
        },
      },
    ],
    companies: undefined,
    dreams: undefined,
    municipalityId: undefined,
    calculationCountry: CalculationCountry.Dk,
    recommendationSettings: {
      companyRecommendation: undefined,
      primaryPensionRecommendation: undefined,
      spousePensionRecommendation: undefined,
      emergencySavingRecommendation: undefined,
      loanRecommendation: {
        type: 'RestrictedLoanToValueRatioGoalSeek',
        ratio: undefined,
        establishmentDetails: {
          foundationFee: 20000,
          // bondPrice: 98,
        },
        // interestRate: 0.06,
        // contributionFeeRate: 0.02,
      },
    },
  },
  expectedResult: {
    debtFactorPayments: {
      baseline: {
        debtFactor: 0,
        houseEquityAtPension: 4369567.70020952,
        averageMonthlyInstallmentUntilPension: 7320.943386194403,
      },
      recommendation: {
        debtFactor: 0.5,
        houseEquityAtPension: 2184783.85010476,
        averageMonthlyInstallmentUntilPension: 2523.112305852879,
      },
      monthlyPaymentDifference: -4797.831080341523,
    },
    liquiditySalaryRatioPayments: {
      baseline: {
        liquidityFactorOfSalary: 6.193548387096774,
        liquiditySumAtPension: 480000,
        monthlySavings: 1000,
      },
      recommendation: {
        liquidityFactorOfSalary: 1.25,
        liquiditySumAtPension: 96875,
        monthlySavings: 0,
      },
      emergencySavingsDifferenceWithInitial: 23125,
    },
    pensionCoverageRatioPayments: {
      calculationCountry: 'Dk',
      dreams: [],
      municipalityId: undefined,
      baseline: {
        personOne: {
          coverageRatio: 0.32742224716855306,
          netPensionDepotAtPension: 700151.388,
          netMonthlyPayment: 0,
          grossMonthlyPayment: 0,
        },
        personTwo: {
          coverageRatio: 0.30438327585202535,
          netPensionDepotAtPension: 341929.764,
          netMonthlyPayment: 0,
          grossMonthlyPayment: 0,
        },
        householdCoverageRatio: 0.31689347147415475,
        householdPensionDepotSum: 1042081.152,
        householdNetMonthlyPayment: 0,
        householdGrossMonthlyPayment: 0,
      },
      recommendation: {
        personOne: {
          coverageRatio: 0.7500297385769866,
          netPensionDepotAtPension: 2526936.066,
          netMonthlyPayment: 4565.856183333333,
          grossMonthlyPayment: 7242.392566666666,
        },
        personTwo: {
          coverageRatio: 0.7500350123152681,
          netPensionDepotAtPension: 2056917.834,
          netMonthlyPayment: 3914.4093666666668,
          grossMonthlyPayment: 6209.063133333333,
        },
        householdCoverageRatio: 0,
        householdPensionDepotSum: 4583853.9,
        householdNetMonthlyPayment: 8480.26555,
        householdGrossMonthlyPayment: 13451.4557,
      },
      householdDelta: {
        primaryDelta: {
          netPensionDepotAtRetirementDifference: 1826784.678,
          netMonthlyPaymentDifference: 4565.856183333333,
          grossMonthlyPaymentDifference: 7242.392566666666,
        },
        spouseDelta: {
          netPensionDepotAtRetirementDifference: 1714988.07,
          netMonthlyPaymentDifference: 3914.4093666666668,
          grossMonthlyPaymentDifference: 6209.063133333333,
        },
        householdNetPensionDepotAtRetirementDifference: 3541772.748,
        householdNetMonthlyPaymentDifference: 8480.26555,
        householdGrossMonthlyPaymentDifference: 13451.4557,
      },
    },
  },
};
