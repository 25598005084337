import { TestProfileWithAnswers } from '@dreamplan/types';
import { CasperAndLouiseProfile } from './casperLouise';
import { FrejaAndWilliamProfile } from './frejaWilliam';
import { JorgenLiseProfile } from './jorgenLise';
import { Katrine } from './katrine';
import { KlausGitteProfile } from './klausGitte';
import { LarsAndMarianneProfile } from './larsMarianne';
import { NeilsProfile } from './niels';

export const AllProfiles: TestProfileWithAnswers[] = [
  CasperAndLouiseProfile,
  JorgenLiseProfile,
  Katrine,
  KlausGitteProfile,
  LarsAndMarianneProfile,
  NeilsProfile,
  FrejaAndWilliamProfile,
];
