export const LoanTypes = {
  NONE: { type: 'NONE', title: 'house_loan_list_NONE' },
  Fastforrentet: { type: 'Fastforrentet', title: 'house_loan_list_Fastforrentet' },
  Banklån: { type: 'Banklån', title: 'house_loan_list_Banklån' },
  F1: { type: 'F1', title: 'house_loan_list_F1' },
  F2: { type: 'F2', title: 'house_loan_list_F2' },
  F3: { type: 'F3', title: 'house_loan_list_F3' },
  F4: { type: 'F4', title: 'house_loan_list_F4' },
  F5: { type: 'F5', title: 'house_loan_list_F5' },
  FA10: { type: 'FA10', title: 'house_loan_list_FA10' },
  FA30: { type: 'FA30', title: 'house_loan_list_FA30' },
  FL1: { type: 'FL1', title: 'house_loan_list_FL1' },
  FL3: { type: 'FL3', title: 'house_loan_list_FL3' },
  FL5: { type: 'FL5', title: 'house_loan_list_FL5' },
  FK: { type: 'FK', title: 'house_loan_list_FK' },
  FKP: { type: 'FKP', title: 'house_loan_list_FKP' },
  FLK: { type: 'FLK', title: 'house_loan_list_FLK' },
};
