import { AnswerItem } from '@dreamplan/types';

export const sharedDefaultAnswers: AnswerItem[] = [
  {
    key: 'isUserRegistered',
    value: 'isUserRegisteredNo',
  },
  {
    key: 'hasAcceptedPrivacyPolicy',
    value: 'true',
  },
  {
    key: 'userEmailAddress',
    value: 'test@dreamplan.io',
  },
  {
    key: 'userPhoneNumber',
    value: '20932211',
  },
  {
    key: 'seeDataSecurity',
    value: 'false',
  },
];
